<template>
  <div class="bg-white">
    <app-modal-customer
      @selectCustomer="selectCustomer"
      :endPoint="endPoint"
      :idEditing="idEditing"
      :screenFlag="screenFlag"
      :date="this.$route.query.date"
      ref="modal_list_customer">

    </app-modal-customer>

    <app-modal-confirm
      ref="delete_book_modal"
      :modal-header-title="$t('reservation.cancel_book_title')"
      @confirmed="cancelBooking"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("reservation.delete_book_content") }}</p>
      </template>
    </app-modal-confirm>

    <app-modal-confirm
      ref="cancel_book_modal"
      :modal-header-title="$t('reservation.cancel_book_title')"
      @confirmed="cancelBooking"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("reservation.cancel_book_content") }}</p>
      </template>
    </app-modal-confirm>

    <app-modal-confirm
      ref="warning_unique_modal"
      :modal-header-title="$t('reservation.check_unique_name_title')"
      @confirmed="createOrUpdate"
    >
      <template v-slot:body>
        <p class="confirm-body">指定されたプランと時間では既に同姓同名の予約がありますが、このまま登録を行いますか？</p>
      </template>
    </app-modal-confirm>

    <validation-observer
      ref="entry"
      v-slot="{ passes, valid, validated }"
    >

      <form class="form-horizontal" v-on:submit.prevent="submitForm">
        <div class="card-body">
          <div v-if="idEditing">
            <div class="form-group row">
              <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.id") }}</label>
              <div class="col-xl-5 col-lg-6 col-sm-6 p-t-8"> {{ entry.id }}</div>
            </div>

          </div>
          <validation-provider
            name="status_publishing"
            rules="required"
            v-slot="{ errors, invalid, validated }"
            v-if="!idEditing"
          >
            <div class="form-group row">
              <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("reservation.status")}} <span
                class="app-require">＊</span>
              </label>
              <div class="col-xl-5 col-lg-6 col-sm-9 align-self-center">
                <app-radio v-for="(item, index) in RESERVATION_CONSTANT.STATUS"
                           :isMarginLeft="index !=0 ? true : false" :key="item.id" :label="item.name"
                           :val="item.id" v-model="entry.real_status_booking" @change="changeTitleBooking"/>
                <label v-if="invalid && validated" class="error">
                  {{ errors[0] }}
                </label>
              </div>
            </div>
          </validation-provider>
          <div v-else>
            <div class="form-group row">
              <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("reservation.status")}}</label>
              <div class="col-xl-5 col-lg-6 col-sm-6 p-t-8"> {{ entry.real_status_text }}</div>
            </div>

          </div>
          <div class="form-group row mb-0">
            <label class="col-xl-1 col-lg-3 col-sm-3 col-form-label">{{ $t("reservation.date") }} <span
              class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-9 col-sm-9 mb-3">
              <app-date-range-picker
                name="reservation_date"
                v-model="entry.date"
                timePicker24Hour="false"
                rules="required"
                format="YYYY-MM-DD"
                @input="entry.date = $event"
                :minDate="minDate"
                @change="loadUnitTime"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("reservation.mansion") }}
              <span
                class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-9 col-sm-9">
              <app-select
                name="apartment_id"
                rules="required"
                :options-data="meta.apartments"
                v-model="entry.apartment_id"
                v-if="!idEditing"
              />
              <p @click="handlerApartmentNameClick(entry.apartment_id)" :data-original-title="entry.apartment_name"  v-else  style="color: #007bff;"
                  class="apartment_name app-table-p app-cell-tooltip mb-0 link_name mt-2">
                {{entry.apartment_name}}
              </p>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("reservation.facility") }}
              <span
                class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-9 col-sm-9">
              <app-select
                name="facility_id"
                rules="required"
                :options-data="meta.facilities"
                v-model="entry.facility_id"
                v-if="!idEditing"
              />
              <p @click="handlerFacilityNameClick(entry.facility_id)" :data-original-title="entry.facility_name" v-else  style="color: #007bff;"
                 class="apartment_name app-table-p app-cell-tooltip mb-0 link_name mt-2">
                  {{entry.facility_name}}
              </p>
            </div>
          </div>
          <div class="form-group row" v-if="entry.real_status_booking != BLOCK_BOOK">
            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("reservation.plan") }}
              <span
                class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-9 col-sm-9">
              <app-select
                name="plan_id"
                rules="required"
                :options-data="meta.plans"
                v-model="entry.plan_id"
                v-if="!idEditing"
                @change="loadUnitTime"
              />
              <p @click="handlerPlanNameClick(entry.facility_id, entry.plan_id)" :data-original-title="entry.plan_name"  v-else  style="color: #007bff;"
                 class="apartment_name app-table-p app-cell-tooltip mb-0 link_name mt-2">
                {{entry.plan_name}}
              </p>

            </div>
          </div>

          <div class="form-group row mb-0">
            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("reservation.time") }} <span
              class="app-require">＊</span>
            </label>
            <div class="col-xl-2 col-lg-4 col-sm-4 mb-3">
              <app-select
                :rules="`required|opening_time:${entry.close_time}`"
                name="reservation_start_time"
                :options-data="unitTimes.startTime"
                v-model="entry.start_time"
              />
            </div>
            <div class="col-xl-1 col-lg-1 col-sm-1 mt-1" style="text-align: center">
              <span class="align-self-center icon_nga text-center" style="font-size:20px;max-width: 8.3%; margin-top: -15px;font-weight: bold; color : #000;font-family: 'Font Awesome 5 Brands';">~</span>
            </div>

            <div class="col-xl-2 col-lg-4 col-sm-4 mb-3">
              <app-select
                name="reservation_close_time"
                rules="required"
                :options-data="unitTimes.closingTime"
                v-model="entry.close_time"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label num-people" v-if="entry.real_status_booking == NON_BLOCK">{{ $t("reservation.number_of_people") }}
              <span
                class="app-require">＊</span>
            </label>

            <div class="col-xl-5 col-lg-9 col-sm-9 num-people" v-if="entry.real_status_booking == NON_BLOCK">
              <app-input
                name="number_of_people"
                :class="['flex-fill']"
                v-model="entry.num_of_peoples"
                type="number-length:3"
                :rules="VALIDATE.V_TINY_INT_REQUIRED"
              />
            </div>
          </div>
          <div v-if="entry.real_status_booking == NON_BLOCK">
            <div class="form-group row">
              <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customer-meals.customer_member_id") }}</label>
              <div class="col-xl-5 col-lg-9 col-sm-9">
                <div class="row">
                  <div class="col-xl-7 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <app-input
                      name="customer_member_id"
                      :disabled="true"
                      v-model="entry.customer_id"
                      :class="['flex-fill']"
                    />
                  </div>
                  <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <button
                      @click="handleOpenCustomerModal()"
                      class="btn btn-primary w-100"
                      type="button"
                      style="height: 40px !important;"
                    >
                      {{ $t("common.pick_customer") }}
                    </button>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("reservation.name") }}
                <span
                  class="app-require">＊</span>
              </label>
              <div class="col-xl-5 col-lg-9 col-sm-9">
                <app-input
                  name="customer_name"
                  rules="required"
                  type="string:257"
                  :class="['flex-fill']"
                  v-model="entry.customer_name"
                />
              </div>
            </div>
            <div class="form-group row mb-4">
              <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("reservation.phone") }}
              </label>
              <div class="col-xl-5 col-lg-9 col-sm-9">
                <app-input
                  name="customer_phone"
                  type="phone"
                  rules="phone_number"
                  :class="['flex-fill']"
                  v-model="entry.customer_phone"
                  placeholder="00000000000"
                />
              </div>
            </div>
          </div>
          <div v-else>

              <div class="form-group row">
                <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("reservation.repeat_type")}}
                  <span
                    class="app-require">＊</span>
                </label>
                <div class="col-xl-5 col-lg-9 col-sm-9 align-self-center grid">
                  <validation-provider
                    name="repeat_custom_wdays"
                    rules="required"
                    v-slot="{ errors, invalid, validated }"

                    class="grid"
                  >
                  <app-radio v-for="(item, index) in RESERVATION_CONSTANT.REPEAT_TYPE"
                             :isMarginLeft="index !=0 ? true : false" :key="item.id" :label="item.name"
                             :val="item.id" v-model="entry.repeat_type"/>
                    <div class="row" style="margin-top: -10px;margin-bottom: 10px;">
                      <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label"></label>
                      <div class="col-xl-5 col-lg-6 col-sm-6">
                        <label v-if="invalid && validated" class="error">
                          {{ errors[0] }}
                        </label>
                      </div>
                    </div>
                  </validation-provider>
                  <validation-provider
                    name="reservation_pick_date_of_week"
                    :rules="entry.repeat_type == 4 ? 'required' : ''"
                    ref="repeat_custom_wdays"
                    v-slot="{ errors, invalid, validated }"
                  >
                    <div class="form-group row">
                      <div class="col-xl-12 col-lg-12 col-sm-10 gr-icheck ml-4">
                        <app-check-box
                          :label="$t('book-masters.sunday')"
                          v-model="entry.repeat_custom_wdays"
                          class="m-r-20"
                          val="sun"
                          :disabled="entry.repeat_type != 4 ? true : false"
                        />
                        <app-check-box
                          :label="$t('book-masters.monday')"
                          v-model="entry.repeat_custom_wdays"
                          class="m-r-20"
                          val="mon"
                          :disabled="entry.repeat_type != 4 ? true : false"
                        />
                        <app-check-box
                          :label="$t('book-masters.tuesday')"
                          v-model="entry.repeat_custom_wdays"
                          class="m-r-20"
                          val="tue"
                          :disabled="entry.repeat_type != 4 ? true : false"
                        />
                        <app-check-box
                          :label="$t('book-masters.wednesday')"
                          v-model="entry.repeat_custom_wdays"
                          class="m-r-20"
                          val="wed"
                          :disabled="entry.repeat_type != 4 ? true : false"
                        />
                        <app-check-box
                          :label="$t('book-masters.thursday')"
                          v-model="entry.repeat_custom_wdays"
                          class="m-r-20"
                          val="thu"
                          :disabled="entry.repeat_type != 4 ? true : false"
                        />
                        <app-check-box
                          :label="$t('book-masters.friday')"
                          v-model="entry.repeat_custom_wdays"
                          class="m-r-20"
                          val="fri"
                          :disabled="entry.repeat_type != 4 ? true : false"
                        />
                        <app-check-box
                          :label="$t('book-masters.saturday')"
                          v-model="entry.repeat_custom_wdays"
                          class="m-r-20"
                          val="sat"
                          :disabled="entry.repeat_type != 4 ? true : false"
                        />
                        <label v-if="invalid && validated" class="error">
                          カスタムの場合は曜日を選択してください。
                        </label>
                      </div>
                    </div>
                  </validation-provider>
                </div>
              </div>

            <div class="form-group row">
              <label class="col-xl-1 col-lg-3 col-sm-3 col-form-label">{{ $t("reservation.repeat_custom_wdays") }}
                <span v-if="entry.repeat_type != 0"
                  class="app-require">＊</span>
              </label>
              <div class="col-xl-5 col-lg-9 col-sm-9">
                <app-date-range-picker
                  :rules="entry.repeat_type != 0 ? `required|end_date:${entry.date}` : `end_date:${entry.date}`"
                  name="repeat_type"
                  v-model="entry.repeat_term"
                  timePicker24Hour="false"
                  format="YYYY-MM-DD"
                  :minDate="entry.date"
                />
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("reservation.note") }}
            </label>
            <div class="col-xl-5 col-lg-9 col-sm-9">
              <app-text
                name="note"
                type="string1024"
                :class="['flex-fill']"
                v-model="entry.note"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("reservation.customer_note") }}
            </label>
            <div class="col-xl-5 col-lg-9 col-sm-9">
              <app-text
                name="customer_note"
                type="string1024"
                :class="['flex-fill']"
                v-model="entry.customer_note"
              />
            </div>
          </div>
          <div class="card-footer row justify-content-end gr-button">
            <button type="button" :disabled="!valid && validated" class="btn btn-default m-r-10"
                    @click="handlerCancelButton">
              {{ idEditing ?  $t('common.back')  :  $t('common.cancel') }}
            </button>
            <button type="button" class="btn btn btn-danger m-r-10" :disabled="!valid && validated" @click="handleOpenCancelBookModal" v-if="idEditing && entry.status_booking != 2">
              {{ $t('common.cancel_booking') }}
            </button>
            <button class="btn btn-primary" :disabled="!valid && validated" type="submit">
              {{idEditing ? $t('common.save') : $t('common.create')}}
            </button>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>

  import {FACILITIES_CONSTANT} from "../../../constants/facilities";
  import {RESERVATION_CONSTANT} from "../../../constants/reservation";
  import {ENDPOINT} from "@constants/api";
  import {ROUTES} from "@constants";
  import _ from "lodash";


  export default {
    name: "ReservationCreateOrEdit",

    data() {
      const id = this.$route.params.id
      return {
        meta: {
          apartments: [],
          facilities: [{
            id: "",
            name: "--選択--"
          }],
          plans: []
        },
        unitTimes: {
          startTime: [{
            id: "",
            name: "--選択--"
          }],
          closingTime: [{
            id: "",
            name: "--選択--"
          }]
        },
        entry: {
          contents: "",
          information_type: 0,
          apartment_id: this.$route.query.apartment_id ? this.$route.query.apartment_id : "",
          facility_id: this.$route.query.facility_id ? this.$route.query.facility_id : "",
          plan_id: this.$route.query.plan_id ? this.$route.query.plan_id : "",
          customer_id: "",
          customer_phone: "",
          status_booking: 0,
          repeat_custom_wdays: [],
          repeat_type: 0,
          date : this.$route.query.date ? this.$route.query.date : moment().format('YYYY-MM-DD'),
          repeat_term : "",
          real_status_booking : 1,
          start_time: this.$route.query.start_time ? this.$route.query.start_time : "",
          close_time : ""
        },
        minDate : moment().format('YYYY-MM-DD'),
        BLOCK_BOOK : 0,
        NON_BLOCK : 1,
        idEditing: id,
        loading: true,
        allowedExtension: ['image/jpeg', 'image/png'],
        endPoint: ENDPOINT.CUSTOMER_LIST + "?filters[status_enabled][equal]=1&filters[apartment_id][equal]=",
        screenFlag: 2,
        closeTimeData : null,
        firstTimeComponentLoad : true,
        plan_unit_minutes: 0,
      }
    },
    watch: {
      "entry.apartment_id": async function (value) {
        if((this.entry.plan_id == null || this.entry.plan_id == '') && this.$route.query.plan_id) {
          this.entry.plan_id = this.$route.query.plan_id;
        }
        if (value && this.loading) {
          this.endPoint = ENDPOINT.CUSTOMER_LIST + "?filters[status_enabled][equal]=1&filters[apartment_id][equal]=" + value
          await this.updateChangedValue({apartment_id: value, facility_id : this.entry.facility_id,
            plan_id : this.entry.plan_id, date : this.entry.date, status_booking: this.entry.real_status_booking})
        } else {
          this.endPoint = ENDPOINT.CUSTOMER_LIST + "?filters[status_enabled][equal]=1&filters[apartment_id][equal]="
          this.meta.facilities = [{
            id: "",
            name: "--選択--"
          }]
          this.meta.plans = [{
            id: "",
            name: "--選択--"
          }]
          if (this.entry.real_status_booking != this.BLOCK_BOOK) {
            this.unitTimes = {
              startTime: [{
                id: "",
                name: "--選択--"
              }],
              closingTime: [{
                id: "",
                name: "--選択--"
              }]
            }
          }
        }
        if (!this.idEditing) {
          if (this.entry.customer_id) {
            this.entry.customer_phone = '';
            this.entry.customer_name = '';
          }
          this.entry.customer_id = '';
        }
      },
      "entry.facility_id": async function (value) {
        if (value && this.loading) {
            await this.updateChangedValue({facility_id: value, apartment_id : this.entry.apartment_id,
              plan_id : this.entry.plan_id, date : this.entry.date, status_booking: this.entry.real_status_booking})
        } else {
          this.meta.plans = [{
            id: "",
            name: "--選択--"
          }];
          if (this.entry.real_status_booking != this.BLOCK_BOOK) {
            this.unitTimes = {
              startTime: [{
                id: "",
                name: "--選択--"
              }],
              closingTime: [{
                id: "",
                name: "--選択--"
              }]
            }
          }
        }
      },
      "entry.plan_id" : function (value) {
        if (!this.idEditing) {
          this.entry.start_time = "";
          this.entry.close_time = "";
        }
      },
      "entry.real_status_booking" : function (value)  {
          this.changeTitleBooking()
      },
      "entry.start_time" : async function () {
        await this.loadEndTime()
        if (!this.firstTimeComponentLoad) {
          this.closeTimeData = "";
        }
      },
      "entry.repeat_custom_wdays" : function (value, old) {
            if (this.entry.repeat_custom_wdays.length) {
              this.$refs.repeat_custom_wdays?.reset()
            } else {
              this.$refs.repeat_custom_wdays.validate()
            }
        }
    },
    async created() {
      if (this.idEditing) {
        this.loading = false
        await this.$request.get(this.ENDPOINT.BOOK_RESERVATION_EDIT(this.idEditing)).then(res => {
          if (res.hasErrors()) {
            if (res.status === 'error'){
              this.__noticeError(this.$t(res.data.error_msg));
            }
            this.redirectDefaultValue()
          } else {
            this.entry = res.data.data
            this.closeTimeData = res.data.data.close_time == "2359" ? "2400" : res.data.data.close_time
            this.loadUnitTime()
          }
        })
      }
    },

    mounted: async function () {
      $(".tooltip").tooltip("hide");
      this.$request.get(this.ENDPOINT.UI_HELPER_CUSTOMER_MEALS).then(res => {
        this.meta = {
          apartments: res.data.apartments,
          facilities: [{
            id: "",
            name: "--選択--"
          }],
          plans: [{
            id: "",
            name: "--選択--"
          }]
        }
        this.meta.apartments.unshift({
          id: "",
          name: "--選択--"
        })
      })

      let elements = $(".breadcrumb-item")[0];

      $(elements).find("a").attr("href", "/admin/reservation?filters.date.equal="+moment().format('YYYY-MM-DD')+"&filters.status_booking_equal.equal=all&filters.apartment_id.equal=all&filters.start_time_between.between=all&filters.close_time_between.between=all&filters.facility_id.equal=all&filters.plan_id.equal=all")
    },
    methods: {
       updateChangedValue(params) {
         this.$request.get(this.ENDPOINT.UI_HELPER_RESERVATION, params).then(res => {
          this.meta = {
            facilities: res.data.facilities,
            apartments: res.data.apartments,
            plans: res.data.plans
          }

           this.meta.apartments.unshift({
             id: "",
             name: "--選択--"
           })
           this.meta.facilities.unshift({
             id: "",
             name: "--選択--"
           })
           this.meta.plans.unshift({
             id: "",
             name: "--選択--"
           })
          this.$forceUpdate()
          // this.meta.facilities = res.data.facilities
        })
      },
      loadUnitTime() {
        if((this.entry.start_time == null || this.entry.start_time == '') && this.$route.query.start_time) {
          this.entry.start_time = this.$route.query.start_time;
        }
        let vm = this;
        if (vm.entry.plan_id) {
          if (vm.entry.facility_id && vm.entry.apartment_id) {
            this.handlerGetTime(vm)
          }
        } else {
          if (vm.entry.facility_id && vm.entry.apartment_id) {
            this.handlerGetTime(vm)
          }
        }
      },
      async loadEndTime() {
        let vm = this;
        if (vm.entry.start_time == "") {
          this.$request.get(this.ENDPOINT.UI_HELPER_RESERVATION, {
            apartment_id: vm.entry.apartment_id,
            facility_id: vm.entry.facility_id,
            plan_id: vm.entry.plan_id,
            start_time : vm.entry.start_time ? vm.entry.start_time : '',
            date: vm.entry.date,
            status_booking : vm.entry.real_status_booking,
          }).then(res => {
            this.unitTimes.startTime  = res.data.start_time
            this.unitTimes.closingTime  = res.data.end_time
            this.plan_unit_minutes = res.data.plan_unit_minutes;
            this.unitTimes.startTime.unshift({
              id: "",
              name: "--選択--"
            })
            this.unitTimes.closingTime.unshift({
              id: "",
              name: "--選択--"
            })
            if (vm.entry.real_status_booking != this.BLOCK_BOOK) {
              this.unitTimes = {
                startTime: this.unitTimes.startTime,
                closingTime:[{
                  id: "",
                  name: "--選択--"
                }]
              }
            } else {
              this.unitTimes = {
                startTime: this.unitTimes.startTime,
                closingTime: this.unitTimes.closingTime
              }
            }

          })
        }
        if (vm.entry.plan_id && vm.entry.facility_id && vm.entry.apartment_id && vm.entry.start_time) {
         await this.$request.get(this.ENDPOINT.UI_HELPER_RESERVATION, {
            apartment_id: vm.entry.apartment_id,
            facility_id: vm.entry.facility_id,
            plan_id: vm.entry.plan_id,
            start_time : vm.entry.start_time ? vm.entry.start_time : '',
            status_booking : vm.entry.real_status_booking,
            date:vm.entry.date
          }).then(res => {
            this.unitTimes.closingTime = res.data.end_time
            if (vm.entry.real_status_booking == this.BLOCK_BOOK) {
              this.unitTimes.closingTime.unshift({
                id: "",
                name: "--選択--"
              })
            }
            if (vm.entry.real_status_booking == this.NON_BLOCK) {
              let [firstKey] = Object.keys(this.unitTimes.closingTime)
              this.entry.close_time = this.unitTimes.closingTime[firstKey].id
            } else {
                this.entry.close_time = _.cloneDeep(this.closeTimeData);
                this.firstTimeComponentLoad = false;
            }
          })
        }
      },
      changeTitleBooking() {
         if (!this.idEditing) {
           this.loadEndTime()
         }

        let elements = $(".breadcrumb-item")[1];
        if (this.idEditing) {
          if (this.entry.real_status_booking == this.NON_BLOCK) {
            $(elements).html(this.$t('breadcrumbs.Reservation Edit'))
          } else {
            $(elements).html(this.$t('breadcrumbs.Reservation Block Edit'))
          }
        } else {
          if (this.entry.real_status_booking == this.NON_BLOCK) {
            $(elements).html(this.$t('breadcrumbs.Reservation Create'))
          } else {
            $(elements).html(this.$t('breadcrumbs.Reservation Block Create'))
          }
        }

      },

      handleOpenCustomerModal() {
        this.endPoint = ENDPOINT.CUSTOMER_LIST + "?filters[status_enabled][equal]=1&filters[apartment_id][equal]=" + this.entry.apartment_id
        this.$refs.modal_list_customer.show();
      },
      handleOpenCancelBookModal() {
         if (this.entry.real_status_booking == this.BLOCK_BOOK) {
           this.$refs.delete_book_modal.show();
         } else {
           this.$refs.cancel_book_modal.show();
         }
      },
      selectCustomer(data) {
        this.entry.customer_id = data.row.id
        this.entry.customer_phone = data.row.phone_number
        this.entry.customer_name = data.row.name
        this.$refs.modal_list_customer.hide();
      },
      async submitForm() {
        if (!await this.$refs.entry.validate()) {
          return;
        }
        if (this.entry.real_status_booking != this.BLOCK_BOOK) {
          let uniqueName = await this.checkUniqueName()
          if (!this.entry.customer_id && !this.entry.customer_phone && uniqueName) {
            this.$refs.warning_unique_modal.show();
            return ;
          }
        }
        await this.createOrUpdate()
      },
      async checkUniqueName() {
        let res = null;

        res = await this.$request.post(this.ENDPOINT.BOOK_RESERVATION_CHECK_UNIQUE, {
          entry_id : this.idEditing,
          customer_name : this.entry.customer_name,
          start_time : this.entry.start_time,
          close_time : this.entry.close_time,
          plan_id : this.entry.plan_id,
          date : this.entry.date
        });
        return res.data.success
      },
      async createOrUpdate() {
        let bookDate = moment(`${this.entry.date} ${this.entry.start_time}`, "YYYY-MM-DD H:mm:ss").format("YYYY-MM-DD H:mm:ss");
        var str = new Date().toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' });
        var date = new Date(str);
        if (Date.parse(bookDate) < date && this.entry.real_status_booking != this.BLOCK_BOOK) {
          this.__noticeError('予約の開始日付と時間は現在の時刻以降の日時で入力してください。');
          return;
        }
        if (!await this.$refs.entry.validate()) {
          return;
        }
        let res = null;
        let msg = "";

        if (this.idEditing) {
          res = await this.$request.patch(this.ENDPOINT.BOOK_RESERVATION_EDIT(this.idEditing), this.entry);
          msg = this.$t("common.msg update ok")
        } else {
          res = await this.$request.post(this.ENDPOINT.BOOK_RESERVATION_CREATE, this.entry);
          msg = this.$t("common.msg create ok")
        }
        if (res.hasErrors()) {
          this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
          if (res.status === 'error') {
            this.redirectDefaultValue()
          }
        } else {
          this.__noticeSuccess(msg);
          this.redirectDefaultValue();
        }
      },
      async cancelBooking() {
        const res = await this.$request.post(this.ENDPOINT.BOOK_RESERVATION_CANCEL(this.idEditing));
        if (!res.hasErrors()) {
          this.__noticeSuccess(this.$t("common.msg delete ok"));
          this.redirectDefaultValue();
        } else {
          this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
          this.$refs.delete_book_modal.hide();
          this.$refs.cancel_book_modal.hide();
          if (res.status === 'error') {
            this.redirectDefaultValue()
          }
        }
      },
      handlerCancelButton() {
        if(this.$store.state.setting.prevRouter.name === ROUTES.ADMIN.RESERVATION_LIST || this.$store.state.setting.prevRouter.name === ROUTES.ADMIN.BOOKING_SCHEDULE_LIST) {
          this.__redirectBack()
        } else {
          this.redirectDefaultValue()
        }
      },
      handlerApartmentNameClick(apartment_id) {
        let routeApartment = this.$router.resolve({name: this.ROUTES.ADMIN.APARTMENT_EDIT, params: {id: apartment_id}});
        window.open(routeApartment.href, '_blank');
      },
      handlerFacilityNameClick(facility_id) {
        let routeFacility = this.$router.resolve({name: this.ROUTES.ADMIN.FACILITY_EDIT, params: {id: facility_id}})
        window.open(routeFacility.href, '_blank');
      },
      handlerPlanNameClick(facility_id, plan_id) {
        let routePlan = this.$router.resolve({name: this.ROUTES.ADMIN.FACILITY_PLAN_EDIT, params: {facility_id: facility_id, id : plan_id}});
        window.open(routePlan.href, '_blank');
      },
      getStatusName(status) {

            let data = RESERVATION_CONSTANT.FILTER_STATUS.find(({id}) => id === status);
            return data.name
      },
      redirectDefaultValue() {
        if (this.$route.query.date) {
          this.$router.push({
            name: this.ROUTES.ADMIN.BOOKING_SCHEDULE_LIST,
            query:  {
              'status_booking' : 'all',
              'date' : this.$route.query.date,
              'apartment_id' : '',
              'customer_name' : ''
            }
          });
        } else {
          this.$router.push({
            name: this.ROUTES.ADMIN.RESERVATION_LIST,
            query: {
              'filters.date.equal' : moment().format('YYYY-MM-DD'),
              'filters.status_booking_equal.equal' : 'all',
              'filters.apartment_id.equal' : 'all',
              'filters.start_time_between.between' : 'all',
              'filters.close_time_between.between' : 'all',
              'filters.facility_id.equal' : 'all',
              'filters.plan_id.equal' : 'all'
            }
          });
        }
      },
      handlerGetTime(vm) {
        this.$request.get(this.ENDPOINT.UI_HELPER_RESERVATION, {
          apartment_id: vm.entry.apartment_id,
          facility_id: vm.entry.facility_id,
          plan_id: vm.entry.plan_id,
          start_time : vm.entry.start_time ? vm.entry.start_time : '',
          date: vm.entry.date,
          status_booking : vm.entry.real_status_booking,
        }).then(res => {
          this.unitTimes.startTime  = res.data.start_time
          this.unitTimes.closingTime  = res.data.end_time
          this.plan_unit_minutes = vm.entry.plan_id ? res.data.plan_unit_minutes : null;
          if(res.data.start_time.length == 0) {
            this.unitTimes.closingTime = [{ id: "", name: "--選択--"}];
          }
          this.unitTimes.startTime.unshift({
            id: "",
            name: "--選択--"
          })

        })
      }
    }
  }
</script>

<style>
  @media (min-width: 768px) and (max-width: 1400px) {
    .icon_nga {
      width: 100%;
    }
    .num-people {
      padding-top: 20px;
    }
  }
  .link_name:hover {
    cursor: pointer;
    color: darkblue !important;
  }
  .daterangepicker.show-calendar td.disabled,
  .daterangepicker.show-calendar option.disabled{
    text-decoration: inherit;
  }
</style>
